<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Profile</span>
      </template>
      <user-settings-general :user="user" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="BriefcaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Business Details</span>
      </template>
      <user-business-deatils :user="user" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="PieChartIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">My Discount Chart</span>
      </template>
      <user-discount-plan />
    </b-tab>  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import UserSettingsGeneral from './UserSettingsGeneral.vue'
import UserDiscountPlan from './UserDiscountPlan.vue'
import UserService from '../../services/user.service'
import UserBusinessDeatils from './UserBusinessDeatils.vue'

export default {
  components: {
    UserSettingsGeneral,
    UserDiscountPlan,
    UserBusinessDeatils,
    BTabs,
    BTab,
  },
  data() {
    return {
      user: '',
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      UserService.getUserInfo().then(
        response => {
          this.user = response
        },
        error => {},
      )
    },
  },
}
</script>
