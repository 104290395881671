<!-- eslint-disable import/no-unresolved -->
<template>
  <div>
    <b-card>

      <b-form>

        <!-- accoint details tab -->
        <validation-observer
          ref="invoiceRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Business Details
              </h5>
              <!-- <small class="text-muted">Please fill out this E-invoicing Form, with the required information</small> -->
            </b-col>
            <!-- Contact Number -->
            <!-- Business Registration No -->
            <b-col md="12">
              <b-form-group
                label-for="business_registration_no"
              >
                <template v-slot:label>
                  Business Registration No <span class="text-danger">*</span>
                </template>
                <small>Please insert "NA" if you're an Individual user. E.g. 111222333444 or 12345678-X. (Refer to company SSM)</small>
                <validation-provider
                  #default="{ errors }"
                  name="Business Registration No"
                  rules="required"
                >
                  <b-form-input
                    id="business_registration_no"
                    v-model="data.business_registration_no"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter business registration number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- NRIC/Passport No -->
            <b-col md="12">
              <b-form-group
                label-for="nric_passport_no"
              >
                <template v-slot:label>
                  NRIC/Passport No <span class="text-danger">*</span>
                </template>
                <small>Please insert "NA" if you're a Company user </small>
                <validation-provider
                  #default="{ errors }"
                  name="NRIC/Passport No"
                  rules="required"
                >
                  <b-form-input
                    id="nric_passport_no"
                    v-model="data.nric_passport_no"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter NRIC/Passport number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Tax Identification Number -->
            <b-col md="12">
              <b-form-group
                label-for="tin"
              >
                <template v-slot:label>
                  Tax Identification Number <span class="text-danger">*</span>
                </template>
                <small>Tax No issued by LHDN: E.g.  C1234567890 .    Please insert "NA" if not applicable </small>
                <validation-provider
                  #default="{ errors }"
                  name="Tax Identification Number"
                  rules="required"
                >
                  <b-form-input
                    id="tin"
                    v-model="data.tax_identification_number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter tax identification number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- SST Number -->
            <b-col md="12">
              <b-form-group
                label-for="sst_number"
              >
                <template v-slot:label>
                  SST Number <span class="text-danger">*</span>
                </template>
                <small>E.g. STN-YYMM-XXXXXXXX. Please insert "NA" if it's a non-SST registered company</small>
                <validation-provider
                  #default="{ errors }"
                  name="SST Number"
                  rules="required"
                >
                  <b-form-input
                    id="sst_number"
                    v-model="data.sst_number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter SST number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Tourism Tax Registration Number -->
            <b-col md="12">
              <b-form-group
                label-for="tourism_tax_registration_number"
              >
                <template v-slot:label>
                  Tourism Tax Registration Number <span class="text-danger">*</span>
                </template>
                <small>Please insert "NA" if not applicable</small>
                <validation-provider
                  #default="{ errors }"
                  name="Tourism Tax Registration Number"
                  rules="required"
                >
                  <b-form-input
                    id="tourism_tax_registration_number"
                    v-model="data.tourism_tax_registration_number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter tourism tax registration number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- MSIC Code -->
            <b-col md="12">
              <b-form-group
                label-for="msic_code"
              >
                <template v-slot:label>
                  MSIC Code <span class="text-danger">*</span>
                </template>
                <small>Please refer to this URL if you're unsure your MSIC code: (Refer to company SSM: Article14)

                  <a href="https://sdk.myinvois.hasil.gov.my/codes/msic-codes/">https://sdk.myinvois.hasil.gov.my/codes/msic-codes/</a>
                </small>
                <validation-provider
                  #default="{ errors }"
                  name="MSIC Code"
                  rules="required"
                >
                  <b-form-input
                    id="msic_code"
                    v-model="data.msic_code"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter MSIC code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                @click="updateInfo"
              >Submit</b-button>
            </b-col>
          </b-row>
        </validation-observer>
      <!-- address  -->
      </b-form>
    </b-card>

  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import UserService from '../../services/user.service'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BFormInput,
    BForm,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      stateOptions: [],
      rankOptions: [],
      planOptions: [],
      countryOptions: [],
      required,
      showIsCompany: false,
      email,
      gender: '',
      accountTypeOptions: [
        { value: 'individual', label: 'Individual Account' },
        { value: 'company', label: 'Company Account' },
      ],
      data: {},
      date: '',
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
    }
  },
  created() {
    this.getUserBusinessInfo()
  },
  methods: {
    getUserBusinessInfo() {
      UserService.getBusinessDetailseData().then(
        response => {
          if (response.user_details) {
            this.data = response.user_details
          }
        },
      )
    },
    updateInfo() {
      this.$refs.invoiceRules.validate().then(success => {
        if (success) {
          UserService.addOrUpdateBusinessDetails(this.data).then(
            response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'User Update',
                  text: response.message,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            },
            error => {
              const self = this
              if (error.response.data.errors != undefined) {
                Object.entries(error.response.data.errors).map((value, key) => {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error',
                      text: value[1][0],
                      icon: 'EditIcon',
                      variant: 'danger',
                    },
                  })
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    text: error.response.data.message,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }
            },
          )
        }
      })
    }
  },
}

</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  </style>
