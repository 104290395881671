var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-form',[_c('validation-observer',{ref:"invoiceRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Business Details ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"business_registration_no"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Business Registration No "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('small',[_vm._v("Please insert \"NA\" if you're an Individual user. E.g. 111222333444 or 12345678-X. (Refer to company SSM)")]),_c('validation-provider',{attrs:{"name":"Business Registration No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business_registration_no","state":errors.length > 0 ? false : null,"placeholder":"Enter business registration number"},model:{value:(_vm.data.business_registration_no),callback:function ($$v) {_vm.$set(_vm.data, "business_registration_no", $$v)},expression:"data.business_registration_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"nric_passport_no"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" NRIC/Passport No "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('small',[_vm._v("Please insert \"NA\" if you're a Company user ")]),_c('validation-provider',{attrs:{"name":"NRIC/Passport No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nric_passport_no","state":errors.length > 0 ? false : null,"placeholder":"Enter NRIC/Passport number"},model:{value:(_vm.data.nric_passport_no),callback:function ($$v) {_vm.$set(_vm.data, "nric_passport_no", $$v)},expression:"data.nric_passport_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"tin"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tax Identification Number "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('small',[_vm._v("Tax No issued by LHDN: E.g. C1234567890 . Please insert \"NA\" if not applicable ")]),_c('validation-provider',{attrs:{"name":"Tax Identification Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tin","state":errors.length > 0 ? false : null,"placeholder":"Enter tax identification number"},model:{value:(_vm.data.tax_identification_number),callback:function ($$v) {_vm.$set(_vm.data, "tax_identification_number", $$v)},expression:"data.tax_identification_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"sst_number"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" SST Number "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('small',[_vm._v("E.g. STN-YYMM-XXXXXXXX. Please insert \"NA\" if it's a non-SST registered company")]),_c('validation-provider',{attrs:{"name":"SST Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sst_number","state":errors.length > 0 ? false : null,"placeholder":"Enter SST number"},model:{value:(_vm.data.sst_number),callback:function ($$v) {_vm.$set(_vm.data, "sst_number", $$v)},expression:"data.sst_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"tourism_tax_registration_number"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tourism Tax Registration Number "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('small',[_vm._v("Please insert \"NA\" if not applicable")]),_c('validation-provider',{attrs:{"name":"Tourism Tax Registration Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tourism_tax_registration_number","state":errors.length > 0 ? false : null,"placeholder":"Enter tourism tax registration number"},model:{value:(_vm.data.tourism_tax_registration_number),callback:function ($$v) {_vm.$set(_vm.data, "tourism_tax_registration_number", $$v)},expression:"data.tourism_tax_registration_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"msic_code"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" MSIC Code "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('small',[_vm._v("Please refer to this URL if you're unsure your MSIC code: (Refer to company SSM: Article14) "),_c('a',{attrs:{"href":"https://sdk.myinvois.hasil.gov.my/codes/msic-codes/"}},[_vm._v("https://sdk.myinvois.hasil.gov.my/codes/msic-codes/")])]),_c('validation-provider',{attrs:{"name":"MSIC Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"msic_code","state":errors.length > 0 ? false : null,"placeholder":"Enter MSIC code"},model:{value:(_vm.data.msic_code),callback:function ($$v) {_vm.$set(_vm.data, "msic_code", $$v)},expression:"data.msic_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.updateInfo}},[_vm._v("Submit")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }